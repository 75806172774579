$darkBlue: #003f80;
$tenueBlue: #d5e3f2;
$whiteBlue: #ccdce4;
$whiteGray: #a9abae;
$darkGray: #606062;
$parragraphColor: #606062;
$red: #ed3237;
$tableWithoutSelect: #003f80 0.6;
$tableSelect: #003f80 0.2;
$font-Secular: "PT Sans Caption", sans-serif;

@import "./components/Layouts/pagination.scss";
@import "./styleConstants.scss";

body {
  //height: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 700;
}

/* BOOTSTRAP COLOR CAHNGE */
.btn-primary {
  background-color: $darkBlue;
  color: $white;
  padding: 0.3rem 1rem;
}

.btn-primary:hover {
  background-color: $clearBlue;
  color: $white;
}

// .btn-primary:disabled{
//   background-color: $darkGray;
//   color:$white;
//   opacity: 0.3;
//   border: 1.5px solid $darkGray;
// }

.title-blue {
  color: #01a0f6;
  font-style: bold;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  padding-bottom: 0px;
}

.title-blue-admin {
  color: #005DBF;
  font-style: bold;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  padding-bottom: 0px;
  margin-bottom: 30px;
}

.ord-title-blue {
  color: #00b4cc;
  font-style: bold;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  padding-bottom: 0px;
}

.title-black {
  width: 120px;
  height: 48px;
  font-size: 48px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  align-items: center;
  color: #58595b;
}

.pagination {
  color: $darkBlue;
  float: right;
}

.text-primary {
  color: $darkBlue !important;
}

.hoverPointer:hover {
  cursor: pointer;
}

.margin-left-3rem {
  margin-left: 3rem;
}

.pagination-button {
  border: 1px solid #ddd;

  a {
    color: white !important;
    padding: 1rem;
    font-weight: bolder;
    font-size: 1rem;
  }
}

.btn-following {
  background-color: $tenueBlue !important;
}

.btn-secondary {
  background-color: $darkGray !important;
}

.input-blue-border {
  border: 2.5px solid #01a0f6;
  border-radius: 10px;
  color: #005dbf;
  font-weight: 700;
}

.background-ttn {
  width: 100%;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-image: url(../src/assets/img/icons/fondoTTN.svg);
}

.circle-red {
  background: red;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.hover-table-row:hover {
  background-color: $tenueBlue;
  // transform: scale(1.003);
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.1));
  transition: 0.3s all ease-in-out;
}

.float-right {
  float: right;
}

.icons-svg-top-table {
  margin: 0 10px;
  width: 1.7rem;
}

.pop-edit-record {
  position: absolute;
  z-index: 2;
  margin-left: 10rem;
  margin-top: 0;
}

.icons-gear {
  width: 1.5rem;
  // margin: 10px;
}

.icons-edit-record {
  width: 1.5rem;
  padding: 0 0.5rem;
}

.icon-family {
  width: 1.5rem;
  margin-bottom: 5px;
  margin-left: 1%;
}

//CSS meant to edit the react-popup library
// .popUp-ul {
//   margin: 0 !important;
//   list-style: none;
//   padding-left: 0 !important;
//   width: 10rem r !important;
// }

[role="tooltip"].popup-content {
  //max-width: 10rem;
  //width: 150px;
  //height: 4rem;
  border-radius: 5px;
  // border: 1px solid $darkBlue;
  background: $white;
  //padding: 0 0.4rem;
  padding: 0;
}

.popUp-ul {
  margin: 0 !important;
  list-style: none;
  padding-left: 0 !important;
  width: 10rem r !important;
}

.icons-popUp {
  width: 1rem;
  margin-right: 1rem;
}

.icon-family {
  width: 1.5rem;
  margin-bottom: 5px;
  margin-left: 1%;
}

//width: 10rem;
//height: 4rem;
//border-radius: 0;
//border: 1px solid $darkBlue;
//background: $white;
// padding: 0 .4rem;
////}
.popup-arrow {
  display: none;
}

// .icons-popUp {
//   width: 1rem;
//   margin-right: 1rem;
// }

.circle-red-tools {
  background: red;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.click-me:hover {
  cursor: pointer;
  opacity: 0.8;
}

// .disabled {
//   cursor: not-allowed;
//   opacity: 0.5;
// }
.titleTable {
  margin-left: 10%;
}

.input-big-height {
  height: 2.6rem;
}

.ml-75 {
  margin-left: 0.75rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-2rem {
  margin-left: 2rem;
}

.ml-5 {
  margin-left: 3rem;
}

.ml-5rem {
  margin-left: 5rem;
}

.loading {
  z-index: 1059;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: auto;
  top: 40%;
  text-align: center;
}

.loading::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: -1;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-2rem {
  margin-left: 2rem;
}

.ml-5 {
  margin-left: 3rem;
}

.ml-5rem {
  margin-left: 5rem;
}

@media (max-width: 1640px) {
  .titleTable {
    margin-left: 2%;
  }
}

.titlesText {
  padding-left: 60px;
}

.textForgotPass {
  align-self: baseline;
}

.btnOutlineDarkBlue {
  transition: 0.3s all ease-in-out;
  color: rgba(0, 93, 191, 1);
  border: 1.5px solid #005dbf;
  border-radius: 5px;
  font-weight: 700;
  min-width: 110px;
  min-height: 19px;
  max-height: 40px;
}

.btnOutlineClearBlue {
  transition: 0.3s all ease-in-out;
  color: $clearBlue;
  border: 1.5px solid $clearBlue;
  border-radius: 5px;
  font-weight: 700;
  min-width: 110px;
  min-height: 19px;
  max-height: 40px;
}

.btnOutlineClearBlue:hover {
  transition: 0.3s all ease-in-out;
  color: $white;
  background-color: $clearBlue;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0);
}

.groupBounding {
  margin-left: 0.5rem;
  display: inline-block;
  padding: 0.5rem 0.3rem;
  transition: 0.3s all ease-in-out;
  min-width: 9.563rem; // 153px;
}

.groupBounding2 {
  margin-left: 0.5rem;
  display: inline-block;
  transition: 0.3s all ease-in-out;
  min-width: 9.563rem; // 153px;
}

.groupBounding:hover {
  cursor: pointer;
  background: rgba(230, 236, 242, 1);
  border-radius: 10px;
  color: rgba(0, 93, 191, 1);
}

.groupBounding:hover .btnAddTable {
  filter: grayscale(0%);
}

.groupBounding a {
  color: rgba(88, 89, 91, 1);
  transition: 0.2s all ease-in-out;
}

.groupBounding a:hover {
  color: rgba(0, 93, 191, 1);
}

.btnAddTable {
  width: 0.973rem; //15.56px
  margin: 0 10px;
  filter: grayscale(100%);
}

.inputsearch {
  min-width: 16.875rem; //270px;
  max-height: 1.688rem; //24px;
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 6px;
  border: none;
}

.inputsearch::placeholder {
  color: $darkBlue;
  padding-left: 5px;
  opacity: 0.4;
}

.text-light-blue {
  color: $clearBlue;
}

.input-text-light-blue {
  border: 1px solid #01a0f6;
  border-radius: 6px;
  max-height: 2rem;
}

.selectInput-react-select .css-yk16xz-control {
  border-radius: 6px;
  border: 1px solid $clearBlue;
  // max-height: 2rem;
}

.paddingLeft1rem {
  padding-left: 1rem;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-80 {
  width: 80%;
}

.input-text-darker-blue {
  color: $darkerBlue;
}

.tooltip>div {
  background-color: #fff !important;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  color: #000;
}

.text-primary {
  color: #005dbf !important;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.form-check-input[type="radio"] {
  border: 2px solid #005dbf;
}

.form-check-input:checked[type="radio"] {
  background-color: #fff;
  appearance: none;
  border-radius: 50%;
  border: 2px solid #005dbf;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-check-input:checked[type="radio"]::before {
  display: flex;
  content: "";
  position: relative;
  border: 4px solid #005dbf;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.form-check-input:focus[type="radio"] {
  box-shadow:none
}

::-webkit-scrollbar {
  width: 6px;
}

/*date picker icon*/
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: url("../src/assets/img/icons/datepickerindicator.svg") no-repeat;
  background-position: right;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $whiteGray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $darkGray;
}

.swal2-styled:focus {
  box-shadow: none;
}

.font-size-label {
  font-size: 12px;
}

.bg-dark-blue {
  background-color: rgba(0, 44, 144, 1);
}

.swal2-cancel {
  border: 1px solid #005dbf;
  border-radius: 0.25em;
  background-color: #005dbf !important;
  color: #fff;
  font-size: 1em;
  transition: 0.3s all ease-in-out;
}

.swal2-cancel:hover {
  background-color: #002c91 !important;
}

.swal2-confirm {
  border: 1.5px solid #003F80 !important;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff !important;
  color: #003F80 !important;
  font-size: 1em;
  transition: 0.3s all ease-in-out;
}

.swal2-confirm:hover {
  border: 1.5px solid #003F80;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #003F80 !important;
  color: #fff !important;
  font-size: 1em;
  transition: 0.3s all ease-in-out;
}

.w-10 {
  width: 10%;
}

.list-style-circle {
  position: relative;
}

.list-style-circle::before {
  content: "•";
  position: absolute;
  right: 105%;
}

label {
  font-family: "PT Sans Caption";
}

.textSansCaption {
  font-family: "PT Sans Caption";
}

.fs-13px {
  font-size: 13px;
}

.bg-input-disabled {
  background-color: #f5f7fa !important;
}

.check-dark-blue {
  border: 1.5px solid $darkBlue !important;
}

.check-dark-blue:checked {
  background-color: $darkBlue !important;
  filter: drop-shadow(0px 1px 4px rgba(0, 93, 191, 0.35));
  border-color: $darkBlue;
}

.check-dark-blue:checked:hover {
  background-color: $darkBlueHover !important;
  border-color: $darkBlueHover;
}

.bg-gray-on-table {
  background-color: $tableEven;
}

.MuiTableHead-root .MuiTableRow-root .MuiTableCell-stickyHeader:first-child {
  border-top-left-radius: 10px;
}

.MuiTableHead-root .MuiTableRow-root .MuiTableCell-stickyHeader:last-child {
  border-top-right-radius: 10px;
}

.darkGray {
  color: $darkGray;
}

.bg-warning-tenue {
  background: rgba(255, 139, 0, 0.3) !important;
}

.bg-warning-tenue:hover {
  background: rgba(255, 139, 0, 0.4) !important;
}

.checkClear {
  background: #ffffff;
  /* Color Principal */
  border: 1.5px solid $clearBlue;
  box-sizing: border-box;
  border-radius: 4px;
}

.checkClear:checked {
  /* Color Principal */
  background: #01a0f6;
  border-radius: 4px;
}

.border-clear-blue {
  border: 1.5px solid $clearBlue;
}

.homeBackground {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 555px;
  height: 346px;
  position: absolute;
  right: 47px;
  bottom: 23px;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.cursorPointer {
  cursor: pointer;
}

.fzXsmall {
  font-size: x-small;
}

.fzXLarger {
  font-size: x-large;
}

.w-70 {
  width: 70%;
}

.bg-table-hover {
  background-color: $tableHover;
}

button:focus {
  outline: -webkit-focus-ring-color auto 0;
}

.labelFont {
  font-size: 0.8rem;
  color: #005dbf;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}

.labelFontGray {
  font-size: 0.8rem;
  color: #58595b;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}

.selectInput {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #005dbf;
  min-height: 1.75rem;
}

.selectInput:disabled {
  opacity: 0.7;
  border-color: rgba(118, 118, 118, 0.3);
}

.inputsearch {
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 6px;
  border: none;
  height: 27px;
}

.addCheckButton {
  width: 20px;
  height: 20px;
  background-image: url("./assets/img/icons/add-check.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.addCheckButton:hover {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url("./assets/img/icons/addbuttonhover.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transition: 0.3s all ease-in-out;
}

.groupAddButton {
  padding: 0.5rem;
}

.groupAddButton:hover {
  background: rgba(0, 93, 191, 0.05);
  border-radius: 10px;
  padding: 0.5rem;
  cursor: pointer;
}

.groupAddButton:hover label {
  color: $darkBlue;
  font-weight: 700;
  cursor: pointer;
}

.groupAddButton:hover .addCheckButton {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url("./assets/img/icons/addbuttonhover.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transition: 0.3s all ease-in-out;
}

.subtitleBlue {
  //styleName: Titulos/Menu;
  font-family: "PT Sans Caption";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1rem;
  letter-spacing: 0em;
  text-align: left;
  color: $darkBlue;
}

.tabHeader {
  border-radius: 10px 10px 0 0;
}

.tabHeader:hover,
.tabHeader:focus {
  border-radius: 10px 10px 0 0;
}

.w-30 {
  width: 30%;
}

.border-dark-blue {
  border: 1.5px solid $darkBlue;
}

.border-clear-grey {
  border: 1.5px solid #cecece !important;
}

.input-darker-blue {
  border: 1.5px solid $darkBlue;
  border-radius: 6px;
  font-family: $font-Secular;
  color: $darkGray;
  padding: 0 0.2rem;
}

.input-date-darker-blue {
  border: 1.5px solid $darkBlue;
  border-radius: 6px;
  font-family: $font-Secular;
  color: $darkBlue;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 0.2rem;
}

.textArea {
  max-height: 6rem;
  min-height: 4rem;
  height: 4.5rem;
  border: 1.5px solid $darkBlue;
  border-radius: 6px;
}

.labelInputFile {
  border: 1px solid #005dbf;
  border-radius: 6px;
  min-width: 172px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
  background-color: #fff;
}

.labelInputFile:hover {
  cursor: pointer;
}

.labelInputFile::before {
  margin-right: 4rem;
  color: rgba(206, 206, 206, 1);
  font-size: 0.8rem;
  // position: absolute;
}

ul .backIconLi {
  width: 30px;
  list-style-image: url("./assets/img/icons/iconBack.svg");
}

.select-input-dark-blue {
  padding: 0 5px;
  border-radius: 6px;
  border: 1.5px solid $darkBlue;
  height: 27px;
}

.labelInputFileDisabled {
  border: 1px solid $darkBlue;
  border-radius: 6px;
  min-width: 172px;
  // width: 10.75rem;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
  background-color: #f5f7fa;
}

.labelInputFileDisabled:hover {
  cursor: pointer;
}

.labelInputFileDisabled::before {
  margin-right: 4rem;
  color: rgba(206, 206, 206, 1);
  font-size: 0.8rem;
  // position: absolute;
}

.whiteGray {
  color: $whiteGray;
}

.clearGray {
  color: $clearGray;
}

.hoverHelp:hover {
  cursor: help;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1rem;
}

.fc-toolbar-title {
  color: #00b4cc;
  font-weight: 700;
  font-size: 16px !important;
  padding-left: 10px;
}

.fc .fc-toolbar {
  justify-content: start !important;
}

.fc-prev-button {
  background-color: white !important;
  border: none !important;
}

.fc-next-button {
  background-color: white !important;
  border: none !important;
}

.fc-icon {
  color: #003f80 !important;
}

.fc-col-header-cell-cushion {
  color: #6e6f7c;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px !important;
  text-transform: capitalize;
}

.fc-col-header-cell-cushion:hover {
  color: #6e6f7c;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px !important;
}

.fc-timegrid-slot-label-cushion {
  font-size: 12px !important;
  color: #6e6f7c;
}

.fc .fc-scrollgrid-liquid {
  border: 1px solid #a3e4ec !important;
  box-sizing: border-box;
  border-radius: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fc-timegrid-event-harness {
  cursor: pointer;
}

#inputTypeColor {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 2rem;
  height: 2rem;
  border: none;
  cursor: pointer;
}

#inputTypeColor::-webkit-color-swatch {
  border-radius: 50%;
  border: 1px solid #A2A3AB;
  ;
}

#inputTypeColor::-moz-color-swatch {
  border-radius: 50%;
  border: 1px solid #A2A3AB;
  ;
}

.tooltipAlert>div {
  background-color: #F39682 !important;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  color: #fff;
}

// .sdp {
//   --theme-color: red !important; /* violet color */
// }

.textHide{
  overflow: hidden;
  text-overflow: ellipsis;
}