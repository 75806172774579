.ord-primaryColor {
  color: #00b4cc;
  /* color: red; */
}

.ord-darkGray {
  color: #58595b;
}

.ord-darkGray:hover {
  color: #f39682;
}

.ord-darkGray-admin {
  color: #6E6F7C;
}

.ord-darkGray-admin:hover {
  color: #01a0f6 !important;
}



.ord-darkGraySub-admin {
  color: #A2A3AB;
}

.ord-darkGraySub-admin:hover {
  cursor: pointer;
  color: #005DBF;
}

.ord-darkGraySub {
  color: #6c757d;
}

.ord-darkGraySub:hover {
  cursor: pointer;
  color: #00b4cc;
}

.ord-darkBlue2:hover {
  cursor: pointer;
  color: #7FADDE !important;
}

.ord-darkBlue2 {
  cursor: pointer;
  color: #A2A3AB !important;
}

.ord-roundInput {
  border-radius: 5px;
  color: rgba(0, 63, 128, 0.5) !important;
  border: 1px solid #a3bad1;
  height: 32px;
  padding-left: 5px;
}

.ordInputAdmission {
  outline: none !important;
  border-radius: 5px;
  border: 1px solid #a3bad1;
  height: 32px;
  padding-left: 7px;
  color: hsl(0, 0%, 50%);
  font-size: 14px;
}

.ordInputAdmission::placeholder {
  color: hsl(0, 0%, 50%);
}

.ord-roundInputActive:focus-visible {
  outline-color: #a3bad1 !important;
}

.ord-roundInput:focus {
  color: gray;
}

.ord-roundInput2 {
  border-radius: 5px;
  color: #A3BAD1;
  border: 1px solid #a3bad1;
  height: 32px;
  padding-left: 5px;
  outline: none;
  font-weight: lighter !important;
  width: 100%;
}

.ord-roundInput-search {
  background-color: #F5F7FA;
  border-radius: 5px;
  color: #6e6f7c;
  border: 1px solid #F5F7FA;
  height: 32px;
  font-size: 12px !important;
}

.ord-roundInput-search2 {
  background-color: #F5F7FA;
  border-radius: 5px;
  color: #6e6f7c;
  border: 1px solid #F5F7FA;
  height: 32px;
  font-size: 12px !important;
}

.ord-roundInput-search:focus {
  color: gray !important;
  background-color: #f5f7fa;
  box-shadow: none;

}

.ord-roundInput-search2:focus {
  color: gray !important;
  background-color: #f5f7fa;
  box-shadow: none;
  border: 1px solid #F5F7FA;

}

.ord-roundInput-search::placeholder {
  color: #A2A3AB;
  font-size: 12px;
}

.fwBold {
  font-weight: bold;
}

.heigt3r {
  height: 3rem;
}

.tLeftBLeftBorder {
  height: 2.3rem;
  border: 1px solid #a3bad1;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
  padding-top: 0.3rem;
}

.tRighttBrightBorder {
  height: 2.3rem;
  border: 1px solid #a3bad1;
  border-radius: 0px 5px 5px 0px;
  text-align: center;
  padding-top: 0.3rem;
}

.pleaceholderDate {
  color: #003f80;
  text-transform: uppercase;
  opacity: 0.5;
}

.ordBtnPrimary {
  color: white;
  background-color: #00b4cc;
  font-family: "PT Sans";
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.ordBtnPrimary2 {
  color: white;
  background-color: #00b4cc;
  font-family: "PT Sans";
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 16.50px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.ordBtnPrimary:disabled {
  background-color: gray;
  border: 1px solid gray;
  color: white;
}

.ordBtnPrimary:hover {
  background-color: #003f80;
  color: white;
}

.ordBtnSecondary {
  border: 1px solid #00b4cc;
  background-color: #003f80 !important;
  color: #00b4cc;
  font-family: "PT Sans";
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.ordBtnSecondary:disabled {
  background-color: gray;
  border: 1px solid gray;
  color: white;
}

.ordBtnSecondary:hover {
  background-color: #00b4cc !important;
  color: white;
}

.ordClearBlueText {
  color: #00b4cc !important;
}

.ordGroupAddButton {
  padding: 0.5rem;
  transition: 100ms all ease-in-out;
}

.svgIconDark {
  filter: brightness(10%) contrast(10%);
}

.svgIconDark:hover {
  filter: none;
}

.ordGroupAddButton:hover {
  background: rgba(0, 93, 191, 0.05);
  border-radius: 10px;
  padding: 0.5rem;
  cursor: pointer;
}

.ordGroupAddButton:hover label {
  color: #00b4cc;
  font-weight: 700;
  cursor: pointer;
}

.ordGroupAddButton:hover .addCheckButton {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url("../assets/img/icons/ordAddbuttonhover.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transition: 0.3s all ease-in-out;
}

.ordGroupAddButtonBlue {
  padding: 0.5rem;
  filter: brightness(0) saturate(100%) invert(21%) sepia(97%) saturate(1180%) hue-rotate(192deg) brightness(92%) contrast(88%);
}

.ordGroupAddButtonBlue:hover {
  background: rgba(0, 93, 191, 0.05);
  border-radius: 10px;
  padding: 0.5rem;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(21%) sepia(97%) saturate(1180%) hue-rotate(192deg) brightness(92%) contrast(88%);

}

.ordGroupAddButtonBlue:hover label {
  filter: brightness(0) saturate(100%) invert(21%) sepia(97%) saturate(1180%) hue-rotate(192deg) brightness(92%) contrast(88%);
  font-weight: 700;
  cursor: pointer;
}

.ordGroupAddButtonBlue:hover .addCheckButton {
  cursor: pointer;
  width: 20px;
  height: 20px;
  filter: brightness(0) saturate(100%) invert(21%) sepia(97%) saturate(1180%) hue-rotate(192deg) brightness(92%) contrast(88%);
  background-image: url("../assets/img/icons/ordAddbuttonhover.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transition: 0.3s all ease-in-out;
}

.ordGrayText {
  color: #6e6f7c;
}

.ordGearBlueHover {
  padding: 0.5rem;
}

.ordGearBlueHover:hover {
  background: rgba(0, 93, 191, 0.05);
  border-radius: 10px;
  padding: 0.5rem;
  cursor: pointer;
}

.ordGearBlueHover:hover label {
  color: #00b4cc;
  font-weight: 700;
  cursor: pointer;
}

.ordGearBlueHover:hover .ordAddCheckButtonGearBlue {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url("../assets/img/icons/ordBlueGear.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transition: 0.3s all ease-in-out;
}

.ordGrayText {
  color: #6e6f7c;
}

.ordAddCheckButtonGearBlue {
  width: 20px;
  height: 20px;
  background-image: url("../assets/img/icons/engraneGrisOscuro.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.ordBtnBar {
  color: white;
  background-color: #00b4cc;
  font-family: "PT Sans";
  font-weight: bold;
  font-size: 15px;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.ordBtnBar:hover {
  opacity: 0.5;
}

.subLabel {
  font-size: 12px;
  opacity: 0.5;
  padding-left: 5px;
}

.ordBtnBar:disabled {
  background-color: gray;
  border: 1px solid gray;
  color: white;
  cursor: not-allowed;
}


.ordBtnPrimary {
  color: white;
  background-color: #00b4cc;
  font-family: "PT Sans";
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.swal2-actions .ordBtnPrimaryModuleAdmin {
  color: white !important;
  background-color: #01A0F6 !important;
  font-family: "PT Sans" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 6px !important;
  border: 1px solid #01A0F6 !important;
}

.swal2-actions .ordBtnPrimaryModuleAdmin:disabled {
  background-color: gray !important;
  border: 1px solid gray !important;
  color: white !important;
}

.swal2-actions .ordBtnPrimaryModuleAdmin:hover {
  background-color: #005DBF !important;
  color: white !important;
}

.swal2-actions .ordBtnPrimaryModuleAssitance,
.ordBtnPrimaryModuleAssitance {
  color: white !important;
  background-color: #1ABCD2 !important;
  font-family: "PT Sans" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 6px !important;
  border: 1px solid #1ABCD2 !important;
  text-decoration: none;
}

.swal2-actions .ordBtnPrimaryModuleAssitance:disabled,
.ordBtnPrimaryModuleAssitance:disabled {
  background-color: gray !important;
  border: 1px solid gray !important;
  color: white !important;
}

.swal2-actions .ordBtnPrimaryModuleAssitance:hover,
.ordBtnPrimaryModuleAssitance:hover {
  background-color: #16a0b3 !important;
  color: white !important;
}

.swal2-actions .ordBtnSecondaryModuleAdmin {
  border: 1px solid #005DBF !important;
  color: #005DBF !important;
  background-color: white !important;
  font-family: "PT Sans" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 6px !important;
}

.swal2-actions .ordBtnSecondaryModuleAdmin:disabled {
  background-color: gray !important;
  border: 1px solid gray !important;
  color: white !important;
}

.swal2-actions .ordBtnSecondaryModuleAdmin:hover {
  background-color: #003f80 !important;
  color: white !important;
}

.swal2-actions .ordBtnSecondaryModuleAssistance,
.ordBtnSecondaryModuleAssistance {
  box-shadow: none !important;
  border: 1px solid #1ABCD2 !important;
  color: #1ABCD2 !important;
  background-color: white !important;
  font-family: "PT Sans" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 6px !important;
  text-decoration: none;
}

.swal2-actions .ordBtnSecondaryModuleAssistance:disabled,
.ordBtnSecondaryModuleAssistance:disabled {
  background-color: gray !important;
  border: 1px solid gray !important;
  color: white !important;
}

.swal2-actions .ordBtnSecondaryModuleAssistance:hover,
.ordBtnSecondaryModuleAssistance:hover {
  background-color: #1ABCD2 !important;
  color: white !important;
}

.swal2-actions .ordBtnDenyModuleAdmin {
  color: white !important;
  background-color: rgba(255, 139, 0, 1) !important;
  font-family: "PT Sans" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 6px !important;
  border: 1px solid rgba(255, 139, 0, 0.1) !important;
}

.swal2-actions .ordBtnDenyModuleAdmin:hover {
  background-color: rgba(255, 139, 0, 0.8) !important;
  color: white !important;
}

/* STYLE MODAL MODULE ASISTENCIAL */

.swal2-actions .ordBtnPrimaryModuleAsistencial {
  color: white !important;
  background-color: #00B4CC !important;
  font-family: "PT Sans" !important;
  min-width: 145px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 6px !important;
  border: 1px solid #00B4CC !important;
}

.swal2-actions .ordBtnPrimaryModuleAsistencialYesContinue {
  color: #00B4CC !important;
  background-color: white !important;
  font-family: "PT Sans" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 6px !important;
  border: 1px solid #00B4CC !important;
}

.swal2-actions .ordBtnPrimaryModuleAsistencial:disabled {
  background-color: gray !important;
  border: 1px solid gray !important;
  color: white !important;
}

.swal2-actions .ordBtnPrimaryModuleAsistencial:hover {
  background-color: #00B4CC !important;
  color: white !important;
  opacity: 0.9;
}

.swal2-actions .ordBtnPrimaryModuleAsistencialYesContinue:hover {
  background-color: #00B4CC !important;
  color: white !important;
}

.swal2-actions .ordBtnSecondaryModuleAsistencial {
  border: 1px solid #003F80 !important;
  color: #fff !important;
  background-color: #003F80 !important;
  font-family: "PT Sans" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 6px !important;
}

.swal2-actions .ordBtnSecondaryModuleAsistencial:disabled {
  background-color: gray !important;
  border: 1px solid gray !important;
  color: white !important;
}

.swal2-actions .ordBtnSecondaryModuleAsistencial:hover {
  background-color: #003f80 !important;
  color: white !important;
  opacity: 0.9;
}

.swal2-actions .ordBtnDenyModuleAsistencial {
  color: white !important;
  background-color: rgba(255, 139, 0, 1) !important;
  font-family: "PT Sans" !important;
  min-width: 110px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19.41px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 6px !important;
  border: 1px solid rgba(255, 139, 0, 0.1) !important;
}

.swal2-actions .ordBtnDenyModuleAsistencial:hover {
  background-color: rgba(255, 139, 0, 0.8) !important;
  color: white !important;
}