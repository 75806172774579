.hoverBtnAdd{
    filter: grayscale(100%);
    // border-radius: 10px;
    // width: 140px;
    // height: 30px;
    // text-align: center;
    // color:#58595B;
    // font-family: PT Sans Caption;
    // font-style: normal;
    // font-weight: bold;
    // font-size: 12px;
    // line-height: 16px;
    // cursor: pointer;
}
.hoverBtnAdd:hover{
    // background-color: #005DBF1D;
    filter: grayscale(0%);
    color: #005DBF;
}
.register_inputs_new{
    height: 27px;
    width: 100%;
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    flex: 1;
    padding :0 .5rem;
}
.register_inputs_new:disabled{
    background-color: #F5F7FA;
    border: 1.5px solid #005DBF;
}
.register_inputs_blue{
    color: #005DBF;
    font-weight: bold;
}
.bottomRow {
    margin-top: 20px ;
    display: inline-flex;
    width: 100%;
    vertical-align: middle;
    padding: 0 0.5rem;
    justify-content: flex-end;
  }
  
  .bottomRow > Button {
    margin-left: 12px;
  }
  .imageInputLabel{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: absolute;
    bottom: 4px;
    padding: 4px;
    background: #fff;
    right: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    display: flex;
}
.changepfp{
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: absolute;
    bottom: 1px;
    padding: 0px;
    right: 2px;
    //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}
.hoverPointer:hover{
    cursor: pointer;
    
}
.hoverBlue:hover{
    background-color:#005DBF;
}
.hoverBlue:hover > img{
    filter: brightness(0) saturate(100%) invert(100%);
}

.imageInputBtn{
    display: none;
 }
 .pr_1{
    padding-right: 1rem
 }
 .modaldiv{
    background: rgba(0, 93, 191, 0.05);
    padding: 10px;
    border-radius: 10px;
 }

.inputFile{
    background-image: url("../../assets/img/icons/Adjuntar.svg");
    background-size: 7%;
    background-repeat: no-repeat;
    background-position: 95%;
}
.inputFile:hover{
    cursor: pointer;
}
.fileLabel{
    max-width: 60%;
}
.registerInputs{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    color : #58595B;
}
.registerInputs:disabled{
    background-color: #F5F7FA;
    border: 1.5px solid #005DBF;
}
.textAreaBig{
    resize: none;
    height: 70px;
    border: 1.5px solid #005DBF;
    border-radius: 6px;
    color : #58595B;
    padding :0 .5rem;
}
.textAreaBig:disabled{
    background-color: #F5F7FA;
    border: 1.5px solid #005DBF;
}
.title{
    margin-top   : 3rem;
}
.subtitle{
    font-family: "PT Sans";
    font-style: normal;
    font-size: 25px;
    line-height: 19px;
    color : #005DBF;
    margin-left: 38px;
}
.labelNew{
    font-weight: 700;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
    align-content: left ;
    // color: #58595B;
    padding: 6px 1px 3px 4px;
}
// .labelNew:hover{
//     color : #005DBF;
// }
.Search{
    border: 1px solid rgba(0, 93, 191, 0.1);
    background-color: rgba(0, 93, 191, 0.1);
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 30px;
    color: #005DBF; 
    margin-top: -2px;
}
.Search::placeholder{
    color:#005DBF70

}